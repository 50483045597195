body {
  margin: 0;
  font-family: 'Arial', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body.modalOpen {
  overflow: hidden;
}
.ReactModalPortal {
  position: relative;
  z-index: 9999;
}
.DefaultModal {
  position: absolute;
  z-index: 999;
  text-align: left;
  width: 400px;
  height: 250px;
  overflow-y: scroll;
  background: transparent;
  padding: 0px;
  position: relative;
}
.DefaultModal:focus-within {
  outline: none;
}

.DefaultModal.ReactModal__Content {
  /* transform: translateX(100%);
  transition: transform 400ms ease-in-out 100ms; */
  /* opacity: 0; */
  transform: rotateZ(-360deg) scale(0.4);
  transform-origin: center center;
  transition: all 400ms ease-in-out;
}
.DefaultModal.ReactModal__Content--after-open {
    transform: rotateZ(5deg) scale(1);
    /* opacity: 1; */
}
.DefaultModal.ReactModal__Content--before-close {
    transform: rotateZ(-360deg) scale(0.4);
    /* opacity: 0; */
}
@media screen and (max-width: 700px) {
  .DefaultModal.ReactModal__Content {
    transform: rotateZ(-360deg) scale(0.4);
  }
  .DefaultModal.ReactModal__Content--after-open {
      transform: rotateZ(60deg) scale(1);
  }
  .DefaultModal.ReactModal__Content--before-close {
      transform: rotateZ(-360deg) scale(0.4);
  }
}

.DefaultOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow-y: scroll;
  overflow: hidden;  
  z-index: 999;  

  display: flex;
  align-items: center;
  justify-content: center;
}
.DefaultOverlay::after {
  content: " ";
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: translate3d(0,0,0);
  /* transition: 100ms opacity linear; */
}
.DefaultOverlay.ReactModal__Overlay--after-open::after {opacity: 1;}
.DefaultOverlay.ReactModal__Overlay--before-close::after {
  opacity: 0 !important;	
  /* transition-delay: 400ms; */
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 20px;
}
button {
  appearance: none;
  background: transparent;
  color: black;
  border: none;
  cursor: pointer;
}
@media screen and (pointer: fine) {
  button:hover {
    opacity: 0.4;
  }
}